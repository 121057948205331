@import url(https://fonts.googleapis.com/css?family=Lato:300,400,900);
/* TimeoutModal less */
.timeout-modal h3 {
  margin-top: 14px;
  text-align: center;
}
.timeout-modal .ant-btn {
  text-transform: uppercase;
  line-height: 44px;
  border-radius: 99px;
  height: 44px;
  margin: 30px 0;
  width: 170px;
}

/* Loading less */
.page-loader {
  height: 100vh;
  width: 100%;
}
.page-loader .loader-text {
  margin-left: -15px;
  margin-bottom: 10px;
  font-size: 16px;
}
.page-loader .loader-icon {
  font-size: 30px;
  font-weight: bolder;
}

/* DistrictSwitcher less */

/* DistrictSwitcherModal less */

/* Sidebar less */
.user-menu {
  margin: -10px 10px 0;
  max-width: 220px;
}

/* Sidebar less */
.main-sider {
  background: #305366;
  color: white;
  height: 100vh;
  overflow: hidden;
}
.main-sider .ant-layout-sider-children {
  width: 240px;
  flex-direction: column;
  background: #305366;
  overflow-y: auto;
  position: fixed;
  height: 100vh;
  display: flex;
}
.main-sider .ant-layout-sider-children::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
  background-color: transparent;
  border: 1px solid transparent;
}
.main-sider .ant-layout-sider-children::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  border: 2px solid #305366;
}
.main-sider .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  box-shadow: 0 0;
}
.main-sider .ant-divider {
  background-color: rgba(255, 255, 255, 0.15);
  margin: 0;
}
.main-sider .ant-menu-inline .anticon {
  font-weight: bold;
}
.main-sider .ant-menu-inline .ant-menu-item:not(:last-child),
.main-sider .ant-menu-inline .ant-menu-submenu:not(.ant-menu-submenu-open) .ant-menu-submenu-title:not(:last-child) {
  margin-bottom: 4px;
}
.main-sider .ant-menu-inline .ant-menu-item:hover,
.main-sider .ant-menu-inline .ant-menu-submenu:not(.ant-menu-submenu-open) .ant-menu-submenu-title:hover {
  background: rgba(0, 0, 0, 0.15);
}
.main-sider .ant-menu-inline .ant-menu-item.ant-menu-item-selected,
.main-sider .ant-menu-inline .ant-menu-submenu:not(.ant-menu-submenu-open) .ant-menu-submenu-title.ant-menu-item-selected {
  background: #f294bb;
}
.main-sider .ant-menu-inline .ant-menu-item-divider {
  background-color: rgba(238, 238, 238, 0.1);
}
.main-sider .ant-menu-inline .ant-menu-submenu-title {
  padding-left: 24px !important;
  border-width: 1px 0 0;
  padding-right: 10px;
  margin: -1px 0 0;
}
.main-sider .ant-menu-inline .ant-menu-submenu-title a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}
.main-sider .ant-menu-inline .ant-menu-submenu-title .add-class-button {
  cursor: pointer;
}
.main-sider .ant-menu-inline .hide-arrow .ant-menu-submenu-arrow {
  display: none;
}
/* SidebarLayout less */

/* Header less */
.app-header {
  display: flex;
  flex-direction: row;
}
.app-header .title-col {
  display: flex;
  flex-direction: column;
  line-height: normal;
  justify-content: space-between;
}
.app-header .title-col .app-header-title {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-bottom: 4px;
}
.app-header .title-col .app-header-title h2 {
  margin-bottom: 0;
  max-width: 400px;
  font-size: 20px;
}
.app-header .title-col .app-header-title .extra {
  margin-left: 20px;
  padding-top: 2px;
  font-size: 12px;
  color: #777;
}
.app-header .title-col .app-header-title.has-menu {
  padding-bottom: 0;
  padding-top: 6px;
}
.mini-menu {
  border-bottom: 0;
}
.mini-menu .ant-menu-item {
  padding: 0;
  margin-right: 35px;
}
.mini-menu .ant-menu-item.ant-menu-item-selected,
.mini-menu .ant-menu-item:hover {
  border-bottom-color: #52aad5;
}
.mini-menu .ant-menu-item.ant-menu-item-selected a,
.mini-menu .ant-menu-item:hover a {
  color: #52aad5;
}

/* HeaderLayout less */
.app-content {
  padding: 30px 50px 50px;
}

/* Integrations less */
.integrations .ant-card {
  margin-bottom: 20px;
  -webkit-transition: none;
  transition: none;
}

/* SyncProviderBadge less */

/* InviteUsersModal less */

/* Tagger less */
.ant-tag.tagger-tag {
  font-size: 13px;
  height: 25px;
  line-height: 25px;
  border-radius: 99px;
  padding: 0 15px;
}
.ant-tag.tagger-tag .ellipsis {
  max-width: 150px;
}
.ant-tag.tagger-tag .anticon.anticon-cross {
  font-size: 13px;
  margin-left: 5px;
  margin-right: -5px;
}
.ant-tag.tagger-tag.add-tag {
  cursor: pointer;
  border-style: dashed;
  background-color: transparent;
}

/* AccountInfo less */
.district-admin-tags .ant-tag.tagger-tag {
  margin-bottom: 7px;
}
.district-admin-tags .tagger-label {
  display: inline-flex;
  margin-right: 6px;
  max-width: 120px;
  overflow: hidden;
}

/* CreateSchoolModal less */

.modal-body {
  text-align: center;
}

/* Schools less */

/* CreateClassModal less */

/* AddToClassModal less */

/* ClassTagger less */
.class-tagger .ant-tag {
  margin: 4px 8px 4px 0;
}

/* PermissionsModal less */

/* EditStudentModal less */

/* PeopleTable less */
.people-table .first-name div,
.people-table .last-name div,
.people-table .email div {
  max-width: 200px;
}

/* CreateStudentModal less */

/* EditClassModal less */

/* ClassActions less */

/* People less */
.people-context-menu-container {
  width: 220px;
  margin-right: 20px;
}
.people-context-menu-container .school-picker-btn {
  margin-bottom: 16px;
}
.people-context-menu-container.has-schools .ant-menu-vertical {
  max-height: calc(100% - 48px);
}
.people-context-menu-container .ant-menu-vertical {
  border: 1px solid #e8e8e8;
  position: absolute;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
}
.people-context-menu-container .empty-item {
  pointer-events: none;
  text-align: center;
  opacity: 0.6;
  padding: 0;
}

/* Badge less */
.badge {
  width: 10px;
  height: 10px;
  float: right;
  border-radius: 99px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.18);
}
.teacher-engagement-table {
  overflow-x: auto;
}
.teacher-engagement-table .ant-card-body {
  padding: 0px;
}
.teacher-engagement-table .lessons-taught-progress {
  background-color: #ddd;
  min-width: 125px;
  height: 10px;
}
.teacher-engagement-table .lessons-taught-progress .default-progress-bar:first-child {
  background-color: #81c1e0;
}
.teacher-engagement-table .lessons-taught-progress .default-progress-bar:nth-child(2) {
  background-color: #3c7c9b;
}
.teacher-engagement-table .lessons-taught-progress .default-progress-bar:nth-child(3) {
  background-color: #305366;
}
.teacher-engagement-table .lessons-taught-progress .full-progress-bar {
  background-color: #54cb76;
  width: 100%;
}
.teacher-engagement-table .benchmark-proportion {
  margin-left: 20px;
  width: 44px;
}
.teacher-engagement-table .ant-progress-inner {
  border-radius: 0;
  background: #ddd;
}
.teacher-engagement-table .ant-table-tbody tr:first-child {
  font-weight: bolder;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}
.teacher-engagement-table .ant-card-body td:first-child {
  padding-left: 24px;
  /* default is 16px */
}
.teacher-engagement-table .ant-card-body {
  padding: 0;
}
.teacher-engagement-table .ant-card-body th:first-child {
  padding-left: 24px;
  /* default is 16px */
}
.teacher-engagement-table .ant-table .ant-table-thead > tr {
  background-color: #fafafa;
}
.teacher-engagement-table .teacherName {
  padding-left: 24px;
}

/* Badge less */
.badge {
  width: 10px;
  height: 10px;
  float: right;
  border-radius: 99px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.18);
}
.data-teacher-engagement .ant-card-body {
  padding: 0;
}
.data-teacher-engagement .ant-card-body th:first-child {
  padding-left: 24px;
  /* default is 16px */
}
.data-teacher-engagement .ant-table .ant-table-thead > tr {
  background-color: #fafafa;
}
.data-teacher-engagement .teacherName {
  padding-left: 24px;
}
.data-teacher-engagement .teacher-engagement-summary {
  margin: 20px 0px;
  padding: 15px 0px 15px 24px;
}
.data-teacher-engagement .teacher-engagement-summary h3 {
  font-weight: bold;
}
.data-teacher-engagement .teacher-engagement-summary-table {
  max-width: 400px;
}
.data-teacher-engagement .teacher-engagement-summary-table .ant-table thead > tr > th,
.data-teacher-engagement .teacher-engagement-summary-table .ant-table tr:first-child > td:first-child {
  font-weight: normal;
}
.data-teacher-engagement .teacher-engagement-summary-table .ant-table tr:first-child td:not(:first-child) {
  font-weight: bold;
}
.data-teacher-engagement .teacher-engagement-summary-table .ant-table-body {
  margin: 0;
}

.proficiency-bar {
  margin-bottom: 40px;
}
.proficiency-bar h3 {
  margin-bottom: 0;
}
.proficiency-bar .proficiency {
  margin-top: 20px;
}
.proficiency-bar .proficiency .proficiency-band {
  width: 0%;
  -webkit-transition: width 0.75s ease-in-out;
  transition: width 0.75s ease-in-out;
  overflow: hidden;
}
.proficiency-bar .proficiency .ant-col {
  line-height: 90px;
  height: 90px;
  text-align: center;
  font-size: 28px;
  color: white;
}
.proficiency-bar .proficiency .ant-col:first-child {
  background-color: #ec5863;
}
.proficiency-bar .proficiency .ant-col:nth-child(2) {
  background-color: #f6b324;
}
.proficiency-bar .proficiency .ant-col:nth-child(3) {
  background-color: #54cb76;
}
.proficiency-bar .proficiency .ant-col:last-child {
  background-color: #52aad5;
}
.proficiency-bar .key-item {
  line-height: 15px;
}
.proficiency-bar .key-item .key-color {
  margin: 0 8px 0 22px;
  height: 15px;
  width: 15px;
  float: left;
}
.proficiency-bar .key-item:nth-child(1) .key-color {
  background-color: #ec5863;
}
.proficiency-bar .key-item:nth-child(2) .key-color {
  background-color: #f6b324;
}
.proficiency-bar .key-item:nth-child(3) .key-color {
  background-color: #54cb76;
}
.proficiency-bar .key-item:nth-child(4) .key-color {
  background-color: #52aad5;
}

.proficiency-card .ant-table-tbody tr:first-child {
  font-weight: bolder;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}
.proficiency-card .ant-card-body td:first-child {
  padding-left: 24px;
  /* default is 16px */
}
.proficiency-card .ant-card-body {
  padding: 0;
}
.proficiency-card .ant-card-body th:first-child {
  padding-left: 24px;
  /* default is 16px */
}
.proficiency-card .ant-table .ant-table-thead > tr {
  background-color: #fafafa;
}
.proficiency-card .teacherName {
  padding-left: 24px;
}
.proficiency-chart {
  margin-bottom: 40px;
}

.data-motivation .ant-card {
  margin-bottom: 50px;
}
.data-motivation .aggregate-selector-row {
  margin-bottom: 15px;
}
.data-motivation .aggregate-selector-row .aggregate-selector-label {
  margin-top: auto;
  margin-bottom: auto;
}
.data-motivation .aggregate-selector-row .aggregate-selector {
  width: 200px;
  margin-left: 10px;
}
.data-motivation .custom-tooltip {
  padding: 10px 14px;
  width: 300px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.93);
}
.data-motivation .custom-tooltip h3 {
  text-align: center;
}
.data-motivation .custom-tooltip .ant-row-flex {
  text-align: left;
}

.data-overview .stats > .ant-col {
  border-right: 1px solid #eaeaea;
}
.data-overview .stats > .ant-col:last-child {
  border-right: 0;
}
.data-overview .statistic {
  padding: 20px 10%;
  width: 100%;
}
.data-overview .statistic .anticon {
  font-size: 58px;
  margin-right: 10px;
}
.data-overview .statistic .value {
  color: #52aad5;
  font-size: 45px;
}
.data-overview .statistic .text {
  font-size: 17px;
}
.data-overview .lesson-highlight {
  margin-top: 20px;
}
.data-overview .lesson-highlight .ant-card-head-title .sub-title {
  padding-left: 10px;
  font-weight: lighter;
}
.data-overview .lesson-highlight .lesson-buttons {
  margin-top: 24px;
}
.data-overview .lesson-highlight .lesson-buttons .preview-button {
  margin-left: 12px;
}

.data-edit-modal {
  height: 70vh;
}
.data-edit-modal .ant-modal-content {
  height: inherit;
}
.data-edit-modal .ant-modal-body {
  padding-top: 56px;
  height: calc(100% - 50px);
}
.data-edit-modal .ant-modal-footer {
  height: 50px;
}
.data-edit-modal .collapse-container {
  height: 100%;
  overflow-y: scroll;
}
.data-edit-modal .filter-group-label {
  margin: 0;
}
.data-edit-modal .color-picker {
  margin-right: 10px;
  width: 26px;
  height: 30px;
  border: none;
  background-color: inherit;
  padding: 0;
  cursor: pointer;
}
.data-edit-modal .filter-selector {
  margin: 0px 2.5px 10px 2.5px;
}
.data-edit-modal .label-edit-input {
  margin-left: -2px;
  padding: 2px;
  font-size: 1.5em;
  background-color: inherit;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.data-edit-modal .edit-label-icon {
  font-size: 1.25em;
  margin-left: 10px;
}
.data-edit-modal .edit-label-icon:hover {
  color: lightslategrey;
}
.data-edit-modal .delete-btn {
  color: lightcoral;
  border-color: lightcoral;
  background-color: inherit;
}
.data-edit-modal .delete-btn:hover {
  color: red;
  border-color: red;
  background-color: lightpink;
}

.data-context {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 20px;
  margin-bottom: 25px;
}
.data-context .clear-all {
  border-radius: 99px;
  float: right;
}
.data-context .filter-selector {
  width: 220px;
  margin-right: 20px;
  margin-top: 10px;
}
.data-context .data-context-btn-area {
  margin-top: 25px;
  margin-right: 20px;
}
.data-context .get-data-btn {
  margin-top: 5px;
  margin-left: 20px;
  width: 110px;
}
.data-context .edit-btn {
  margin-top: 5px;
  width: 90px;
}


/* Router less */

/* Root less */

/* Mixins */
.flex-align-middle {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.capitalize {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}
.no-pointer {
  pointer-events: none;
}
.rot90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.scroller {
  overflow-y: auto;
  border: 1px solid #e8e8e8;
  padding: 10px;
}
.absoluteCenter {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-avatar .anticon {
  margin-right: 0;
}
.ant-avatar-lg.xlg {
  height: 60px;
  width: 60px;
  line-height: 60px;
  border-radius: 60px;
}
.ant-avatar-lg.xlg > * {
  line-height: 60px;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: #3f6c85;
  border-color: rgba(0, 0, 0, 0.1);
}
.ant-btn.rounded {
  border-radius: 99px;
}
.ant-btn-primary.secondary {
  background-color: #f294bb;
  border-color: #f294bb;
  color: white;
}
.ant-btn-primary.secondary:hover,
.ant-btn-primary.secondary:focus {
  background-color: #f5abc9;
  border-color: #f5abc9;
}
.ant-btn-primary.secondary:active {
  background-color: #ee74a7;
  border-color: #ee74a7;
}
.ant-btn-primary.secondary.ant-btn-background-ghost {
  color: #f294bb;
}
.ant-btn-primary.secondary.ant-btn-background-ghost:hover,
.ant-btn-primary.secondary.ant-btn-background-ghost:focus {
  color: #f5abc9;
}
.ant-btn-primary.secondary.ant-btn-background-ghost:active {
  color: #ee74a7;
}
.ant-btn-primary.secondary[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-clicked.secondary:after {
  border-color: #f294bb;
}
.ant-btn-primary.green {
  background-color: #54cb76;
  border-color: #54cb76;
  color: white;
}
.ant-btn-primary.green:hover,
.ant-btn-primary.green:focus {
  background-color: #68d186;
  border-color: #68d186;
}
.ant-btn-primary.green:active {
  background-color: #3bc161;
  border-color: #3bc161;
}
.ant-btn-primary.green.ant-btn-background-ghost {
  color: #54cb76;
}
.ant-btn-primary.green.ant-btn-background-ghost:hover,
.ant-btn-primary.green.ant-btn-background-ghost:focus {
  color: #68d186;
}
.ant-btn-primary.green.ant-btn-background-ghost:active {
  color: #3bc161;
}
.ant-btn-primary.green[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-clicked.green:after {
  border-color: #54cb76;
}
.ant-btn-primary.google {
  background-color: #db4437;
  border-color: #db4437;
  color: white;
}
.ant-btn-primary.google:hover,
.ant-btn-primary.google:focus {
  background-color: #df584d;
  border-color: #df584d;
}
.ant-btn-primary.google:active {
  background-color: #ca3124;
  border-color: #ca3124;
}
.ant-btn-primary.google.ant-btn-background-ghost {
  color: #db4437;
}
.ant-btn-primary.google.ant-btn-background-ghost:hover,
.ant-btn-primary.google.ant-btn-background-ghost:focus {
  color: #df584d;
}
.ant-btn-primary.google.ant-btn-background-ghost:active {
  color: #ca3124;
}
.ant-btn-primary.google[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-clicked.google:after {
  border-color: #db4437;
}
.ant-btn-primary.clever {
  background-color: #436cf2;
  border-color: #436cf2;
  color: white;
}
.ant-btn-primary.clever:hover,
.ant-btn-primary.clever:focus {
  background-color: #5b7ff4;
  border-color: #5b7ff4;
}
.ant-btn-primary.clever:active {
  background-color: #2252f0;
  border-color: #2252f0;
}
.ant-btn-primary.clever.ant-btn-background-ghost {
  color: #436cf2;
}
.ant-btn-primary.clever.ant-btn-background-ghost:hover,
.ant-btn-primary.clever.ant-btn-background-ghost:focus {
  color: #5b7ff4;
}
.ant-btn-primary.clever.ant-btn-background-ghost:active {
  color: #2252f0;
}
.ant-btn-primary.clever[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-clicked.clever:after {
  border-color: #436cf2;
}
.ant-btn-primary.classLink {
  background-color: #2a98bb;
  border-color: #2a98bb;
  color: white;
}
.ant-btn-primary.classLink:hover,
.ant-btn-primary.classLink:focus {
  background-color: #2fa9d0;
  border-color: #2fa9d0;
}
.ant-btn-primary.classLink:active {
  background-color: #23809e;
  border-color: #23809e;
}
.ant-btn-primary.classLink.ant-btn-background-ghost {
  color: #2a98bb;
}
.ant-btn-primary.classLink.ant-btn-background-ghost:hover,
.ant-btn-primary.classLink.ant-btn-background-ghost:focus {
  color: #2fa9d0;
}
.ant-btn-primary.classLink.ant-btn-background-ghost:active {
  color: #23809e;
}
.ant-btn-primary.classLink[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-clicked.classLink:after {
  border-color: #2a98bb;
}
.ant-btn-primary.yellow {
  background-color: #f6b324;
  border-color: #f6b324;
  color: white;
}
.ant-btn-primary.yellow:hover,
.ant-btn-primary.yellow:focus {
  background-color: #f7bb3c;
  border-color: #f7bb3c;
}
.ant-btn-primary.yellow:active {
  background-color: #eda40a;
  border-color: #eda40a;
}
.ant-btn-primary.yellow.ant-btn-background-ghost {
  color: #f6b324;
}
.ant-btn-primary.yellow.ant-btn-background-ghost:hover,
.ant-btn-primary.yellow.ant-btn-background-ghost:focus {
  color: #f7bb3c;
}
.ant-btn-primary.yellow.ant-btn-background-ghost:active {
  color: #eda40a;
}
.ant-btn-primary.yellow[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-clicked.yellow:after {
  border-color: #f6b324;
}
.ant-btn-primary.blue {
  background-color: #52aad5;
  border-color: #52aad5;
  color: white;
}
.ant-btn-primary.blue:hover,
.ant-btn-primary.blue:focus {
  background-color: #67b4da;
  border-color: #67b4da;
}
.ant-btn-primary.blue:active {
  background-color: #359cce;
  border-color: #359cce;
}
.ant-btn-primary.blue.ant-btn-background-ghost {
  color: #52aad5;
}
.ant-btn-primary.blue.ant-btn-background-ghost:hover,
.ant-btn-primary.blue.ant-btn-background-ghost:focus {
  color: #67b4da;
}
.ant-btn-primary.blue.ant-btn-background-ghost:active {
  color: #359cce;
}
.ant-btn-primary.blue[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-clicked.blue:after {
  border-color: #52aad5;
}
/* Card less */
.ant-checkbox-wrapper.large .ant-checkbox-inner {
  height: 20px;
  width: 20px;
}
.ant-checkbox-wrapper.large .ant-checkbox-inner:after {
  height: 11px;
  left: 5px;
  top: 7px;
}
.form-row {
  margin-bottom: 0;
}
.ant-layout-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.075);
}
.ant-layout-header.dark {
  background: #305366;
}
.ant-table .ant-table-thead > tr > th {
  background: transparent;
  font-weight: bold;
}
.ant-table .ant-table-tbody > tr:hover > td,
.ant-table .ant-table-tbody > tr.ant-table-row-selected > td {
  background: transparent !important;
}
.ant-table-thead tr.ant-table-row-hover > td,
.ant-table-tbody tr.ant-table-row-hover > td,
.ant-table-thead .ant-table-tbody tr:hover > td,
.ant-table-tbody .ant-table-tbody tr:hover > td {
  background-color: rgba(82, 170, 213, 0.15);
}
.ant-table-tbody > tr > td.ant-table-column-sort {
  background: transparent;
}
.ant-table-tbody > tr > td.ant-table-column-sort tr,
.ant-table-thead tr,
.ant-table-tbody > tr > td.ant-table-column-sort > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: transparent;
}
.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  height: 8px;
}
.ant-table-column-sorter-up.on i,
.ant-table-column-sorter-down.on i,
.ant-table-column-sorter-up.on:hover i,
.ant-table-column-sorter-down.on:hover i {
  color: #305366;
}
.ant-table-column-sorter-up i,
.ant-table-column-sorter-down i,
.ant-table-column-sorter-up:hover i,
.ant-table-column-sorter-down:hover i {
  color: #ccc;
  font-size: 17px !important;
}
.ant-table-column-sorter-up:hover i,
.ant-table-column-sorter-down:hover i {
  opacity: 0.85;
}
.ant-menu-horizontal.center-items {
  text-align: center;
}
.ant-menu-horizontal.center-items .ant-menu-item {
  float: none;
  display: inline-block;
}
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 33px;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 7px 12px;
}
.ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled).danger,
.ant-dropdown-menu-submenu-title:not(.ant-dropdown-menu-item-disabled).danger {
  color: #ec5863;
}
.ant-menu-vertical-right .ant-menu-submenu-title {
  padding-left: 33px;
}
.ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-menu-submenu-title > span > .anticon:first-child,
.ant-dropdown-menu-item > a > .anticon:first-child,
.ant-dropdown-menu-submenu-title > a > .anticon:first-child,
.ant-dropdown-menu-item > span .anticon:first-child,
.ant-dropdown-menu-submenu-title > span .anticon:first-child,
.ant-dropdown-menu-item > a .anticon:first-child,
.ant-dropdown-menu-submenu-title > a .anticon:first-child {
  margin-right: 8px;
  min-width: 12px;
}
.ant-menu .ant-btn .anticon {
  margin: 0;
}
.ant-dropdown-menu-light {
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.ant-dropdown-menu-submenu-title {
  font-weight: bolder;
}
.ant-modal-title {
  text-align: center;
  font-size: 1.17em;
}
.full-page-modal {
  width: 100%;
  padding: 0;
}
.full-page-modal .ant-modal {
  padding-bottom: 0;
  margin: 0 !important;
}
.full-page-modal .ant-modal-close > span {
  font-size: 24px;
}
.full-page-modal .full-page-back {
  position: absolute;
  margin: 15px;
  font-size: 32px;
  cursor: pointer;
  opacity: 1;
  -webkit-transition: opacity 0.15s;
  transition: opacity 0.15s;
  z-index: 1;
}
.full-page-modal .full-page-back:hover {
  opacity: 0.9;
}
.full-page-modal .full-page-back:active {
  opacity: 1;
}
.full-page-modal .ant-modal-body {
  overflow: auto;
  height: 100vh;
}
.full-page-modal .ant-modal-content {
  border-radius: 0;
  background: rgba(255, 255, 255, 0.93);
  height: 100vh;
}
@media print {
  .ant-modal-content {
    box-shadow: none;
  }
}
.ant-tooltip {
  pointer-events: none;
}
.ant-tooltip.small {
  font-size: 12px;
}
.activity-dot-tooltip .ant-tooltip-inner {
  text-align: center;
  max-width: 185px;
}
.ant-tag-checkable {
  cursor: pointer;
  background: #fafafa;
  border: 1px solid #d9d9d9;
}
.ant-tag-checkable.ant-tag-checkable-checked {
  background: #305366;
}
@media print {
  .no-print {
    display: none !important;
  }
  .print-no-break {
    page-break-inside: avoid;
  }
}
body {
  font-family: Lato, Helvetica Neue, Helvetica, Arial;
}
.flex-grow {
  flex: 1 1 auto;
  width: 0;
}
.btn-flash {
  -webkit-animation: btn-flash 700ms 10;
          animation: btn-flash 700ms 10;
}
@-webkit-keyframes btn-flash {
  from {
    box-shadow: 0 0 0 0px rgba(242, 148, 187, 0.9);
  }
  to {
    box-shadow: 0 0 0 10px rgba(242, 148, 187, 0);
  }
}
@keyframes btn-flash {
  from {
    box-shadow: 0 0 0 0px rgba(242, 148, 187, 0.9);
  }
  to {
    box-shadow: 0 0 0 10px rgba(242, 148, 187, 0);
  }
}
.blue {
  color: #52aad5;
}
.lighter {
  font-weight: lighter;
}
.disabled {
  opacity: 0.75;
}
.link {
  color: #52aad5;
  cursor: pointer;
}
.link:hover {
  opacity: 0.8;
}
.disabled-link {
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  color: #333333;
}
/* Tables */
td.actions-column,
th.actions-column {
  text-align: right !important;
}

